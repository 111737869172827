import ilustracia from "../../images/strechy fotky/ine prace/1.webp";
import ilustracia2 from "../../images/strechy fotky/ine prace/2.webp";
import ilustracia3 from "../../images/strechy fotky/ine prace/3.webp";
import ilustracia4 from "../../images/strechy fotky/ine prace/4.webp";


export const images_others = [
  {
    original: ilustracia,
    alt: "image2",
    key: "1",
  },
  {
    original: ilustracia2,
    alt: "image2",
    key: "2",
  },
  {
    original: ilustracia3,
    alt: "image3",
    key: "3",
  },
  {
    original: ilustracia4,
    alt: "image4",
    key: "4",
  },
];
